import React from 'react'
import PropTypes from 'prop-types'

import styles from "./gallery.module.scss"

function Gallery ({
  children
}) {
  return(
    <section className={ styles.gallery }>
      {children}
    </section>
  )
}

Gallery.propTypes = {
  children: PropTypes.node.isRequired,
}

Gallery.defaultProps = {

}

export default Gallery
