import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import Nav from '../nav/nav'

import styles from "./intro.module.scss"

function Intro({ className, dimensionalConstraint, constrain, ...rest }) {
  return (
    <Nav
      className={ classNames(styles.intro, styles.introAgain, styles[dimensionalConstraint], { [styles.constrain]: constrain })}
      variant="dark"
      { ...rest }
    />
  )
}

Intro.propTypes = {
  className: PropTypes.string,
  dimensionalConstraint: PropTypes.oneOf(['width', 'height']),
  constrain: PropTypes.bool,
}

Intro.defaultProps = {
  className: null,
  dimensionalConstraint: 'height',
  fillWindow: true,
}

export default Intro
