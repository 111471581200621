import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import autoBind from 'auto-bind'
import { connect } from "react-redux"
import { debounce } from 'lodash'

import { siteLoaded as siteLoadedAction } from '../store'

import Gallery from '../components/gallery/gallery'
import Intro from '../components/intro/intro'
import Layout from '../components/layout/layout'
import Nav from '../components/nav/nav'
import Thumbnail from '../components/thumbnail/thumbnail'
import { Flipper, Flipped } from 'react-flip-toolkit'

import styles from "./index.module.scss"


class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)
    this.state = {
      introConstrain: false,
      introDimensionalConstraint: 'height'
    }
    this.debouncedCheckWindowDimensions = debounce(this.checkWindowDimensions, 50)
  }

  componentDidMount() {
    setTimeout(this.hideIntro, 3250)
    this.checkWindowDimensions()
    window.addEventListener('resize', this.debouncedCheckWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCheckWindowDimensions)
  }

  hideIntro() {
    this.setState({ introConstrain: true })
    setTimeout(this.props.siteLoaded, 250)
  }

  checkWindowDimensions() {
    if ((window.innerWidth / window.innerHeight) > (100 / 74)) {
      this.setState({ introDimensionalConstraint: 'height' })
    } else {
      this.setState({ introDimensionalConstraint: 'width' })
    }
  }

  render () {
    const {
      data: {
        allMarkdownRemark: { edges },
      },
      isLoaded
    } = this.props;
    const { introConstrain, introDimensionalConstraint } = this.state;

    const Poems = edges
      .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
      .map(edge => {
        const { node: { id, fields, frontmatter } } = edge;
        return <Thumbnail
            className="thumbnail"
            date={ frontmatter.date }
            text={ frontmatter.excerpt }
            fontSize={ frontmatter.excerptFontSize }
            key={ id }
            path={ fields.slug }
            hoverText={ frontmatter.title ? frontmatter.title : undefined }
            hoverFontSize={ frontmatter.titleFontSize }
          />
      })

    return (
      <Layout>
        <Flipper flipKey={ isLoaded } retainTransform>
          <Gallery>
            { !isLoaded &&
              <div className={ styles.introContainer }>
                <Flipped
                  flipId="intro"
                  transformOrigin="top left"
                  scale
                  // translate
                >
                  <Intro
                    dimensionalConstraint={ introDimensionalConstraint }
                    constrain={ introConstrain }
                  />
                </Flipped>
              </div>
            }
            {
              isLoaded &&
              <Flipped
                flipId="intro"
                transformOrigin="top left"
                scale
                // translate
              >
                <Nav
                  className="nav"
                />
              </Flipped>
            }
            { isLoaded && Poems }
          </Gallery>
        </Flipper>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  siteLoaded: PropTypes.func.isRequired,
}

const mapStateToProps = ({ isLoaded }) => {
  return { isLoaded }
}

const mapDispatchToProps = dispatch => {
  return { siteLoaded: () => dispatch(siteLoadedAction()) }
}

export default IndexPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: {frontmatter: { draft: { ne: true } }}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY · MM · DD")
            excerpt
            excerptFontSize
            title
            titleFontSize
          }
        }
      }
    }
  }
`
